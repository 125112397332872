
import { reactive, defineComponent, computed, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  showErrorPopup,
  showSuccessPopup,
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableSkeleton from "@/components/skeleton/Table.vue";
import Pagination from "@/components/Pagination.vue";
import useTransferList from "@/core/services/compositions/transfer/useTransferList";
import moment from 'moment';
import { ElNotification } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { numberFormat } from "@/core/helpers/currency-helper";
import { checkPermission } from '@/router/authentication';
import FilterTransfer from "./filter-transfer.vue";
import VueJsonPretty from 'vue-json-pretty';
import ApiService from "@/core/services/ApiService";
import useDownload from "@/core/helpers/download";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterTransfer,
    VueJsonPretty
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const dialogCheckStatusVisible = ref(false);
    const dialogRetryVisible = ref(false);
    const dialogCancelVisible = ref(false);
    const isProcess = ref<boolean>(false);
    const transferHistoryId = ref();
    const transactionId = ref();
    const transactionType = ref();
    const { handleDownloadToFilePDF, isDownloading } = useDownload();

    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("transfer.breadcrumb"), [
      { title: t("transfer.title") }
    ]);

    const paginationRef = ref(null);
    const { query } = useQuery();
    const jsonPayload = ref(null);
    const jsonRespon = ref(null);

    const form = reactive({
      source_account: '',
      destination_account: '',
      type: '',
      rc: '',
      status: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      date: query.value.date || form.date,
      source_account: query.value.source_account || form.source_account,
      type: query.value.type || form.type,
      rc: query.value.rc || form.rc,
      status: query.value.status || form.status,
      destination_account: query.value.destination_account || form.destination_account,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useTransferList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          source_account: '',
          destination_account: '',
          type: '',
          rc: '',
          status: '',
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        date: form.date,
        source_account: form.source_account,
        destination_account: form.destination_account,
        type: form.type,
        rc: form.rc,
        status: form.status,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const showDialogCheckStatus = (id, trxId, type) => {
      dialogCheckStatusVisible.value = true;
      transferHistoryId.value = id;
      transactionId.value = trxId;
      transactionType.value = type;
    }

    const checkStatus = () => {
      isProcess.value = true;
      dialogCheckStatusVisible.value = false;

      const payload = {
        transaction_id: transactionId.value,
        transaction_type: transactionType.value,
      }

      ApiService.setHeader();
      ApiService.post(
        `app/finance/overbooking/status/` + transferHistoryId.value,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Check Transfer Status',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          isProcess.value = false;
        });

    };

    const showDialogRetry = (id) => {
      dialogRetryVisible.value = true;
      transferHistoryId.value = id;
    }

    const retryTransfer = () => {
      isProcess.value = true;
      dialogRetryVisible.value = false;

      ApiService.setHeader();
      ApiService.put(
        `app/finance/overbooking/retry/` + transferHistoryId.value,
        {}
      )
        .then(() => {
          showSuccessPopup({
            title: 'Retry Transfer',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          isProcess.value = false;
        });
    };

    const showDialogCancel = (id) => {
      dialogCancelVisible.value = true;
      transferHistoryId.value = id;
    }

    const cancelTransfer = () => {
      isProcess.value = true;
      dialogCancelVisible.value = false;

      ApiService.setHeader();
      ApiService.put(
        `app/finance/overbooking/cancel/` + transferHistoryId.value,
        {}
      )
        .then(() => {
          showSuccessPopup({
            title: 'Cancel Transfer',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          isProcess.value = false;
        });
    };

    const download = (data) => {
      const transferDate = moment(data.created_at).locale('id').format('YYYYMMDD');
      const channelName = data.summary.channel;
      const institutioneName = data.summary.institution.name;
      const transactionDate = moment(data.summary.transaction_date).locale('id').format('D MMM YYYY');

      handleDownloadToFilePDF(`/app/finance/transfer/download/${data.id}`, `${transferDate} ${channelName} ${institutioneName} Trx ${transactionDate}`);
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      handleChangePage,
      filtered,
      numberFormat,
      checkPermission,
      moment,
      jsonRespon,
      jsonPayload,
      dialogCheckStatusVisible,
      checkStatus,
      showDialogCheckStatus,
      retryTransfer,
      dialogRetryVisible,
      showDialogRetry,
      showDialogCancel,
      dialogCancelVisible,
      cancelTransfer,
      isProcess,
      download,
      isDownloading
    };
  }
});
