
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useTransferType from "@/core/services/compositions/enum/useTransferType";
import useTransferStatus from "@/core/services/compositions/enum/useTransferStatus";

interface Filter {
  source_account: string;
  destination_account: string;
  type: string;
  rc: string;
  status: string;
  date: Array<string>;
}

export default defineComponent({
  name: "filter-transfer",
  components: {},
  setup(props, context) {
    const { query } = useQuery();

    const { transferType } = useTransferType();
    const { transferStatus } = useTransferStatus();

    const data = reactive<Filter>({
      source_account: query.value.source_account || '',
      destination_account: query.value.destination_account || '',
      type: query.value.type || '',
      rc: query.value.rc || '',
      status: query.value.status || '',
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.source_account = '';
      data.destination_account = '';
      data.type = '';
      data.rc = '';
      data.status = '';
      data.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    };

    return {
      data,
      filter,
      reset,
      transferType,
      transferStatus
    };
  },
});
